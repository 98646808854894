import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private sessionTimeoutYears = 3; // เวลาหมดอายุ 3 ปี
  constructor(private router: Router) {}
  // ฟังก์ชันเข้าสู่ระบบและตั้งค่าเวลาหมดอายุ โดยใช้ idcard แทน phone หรือ user_id
  login(idcard: string): void {
    const now = new Date();
    const expires = new Date(now.getTime() + this.sessionTimeoutYears * 365 * 24 * 60 * 60 * 1000); // คำนวณเวลา 3 ปี
    localStorage.setItem('is_logged_in', 'true');
    localStorage.setItem('idcard', idcard); // เก็บค่า idcard แทน phone หรือ user_id
    localStorage.setItem('session_expires_at', expires.toString()); // เก็บเวลาหมดอายุ
    this.redirectToSavedUrl();
  }

  // ฟังก์ชันตรวจสอบการเข้าสู่ระบบและเวลาเซสชันหมดอายุ
  isLoggedIn(): boolean {
    const isLoggedIn = localStorage.getItem('is_logged_in') === 'true';
    const sessionExpiresAt = localStorage.getItem('session_expires_at');
    if (!isLoggedIn || !sessionExpiresAt) return false;

    const now = new Date();
    const expiresAt = new Date(sessionExpiresAt);
    if (now > expiresAt) {
      this.logout(); // หากหมดอายุแล้วทำการออกจากระบบ
      return false;
    }
    return true;
  }

  // ฟังก์ชันออกจากระบบ
  logout(): void {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('idcard'); // ลบ idcard เมื่อออกจากระบบ
    localStorage.removeItem('session_expires_at');
  }

  // ฟังก์ชันดึงข้อมูล idcard
  getIdCard(): string | null {
    return localStorage.getItem('idcard');
  }

  redirectToSavedUrl() {
    const redirectUrl = localStorage.getItem('url_redirect');
    if (redirectUrl) {
      localStorage.removeItem('url_redirect');
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.router.navigate(['/login-confirm-success']);
    }
  }
}
