<div class="main-error-container">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <img class="icon-img-logo" src="image/icon/icon-logo-mitrphol.svg">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <img class="icon-no-ss-bg-red" src="image/icon/icon-no-ss-bg-red.svg">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="p-msg-error">{{msgError}}</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col  text-center">
                <button type="button" class="btn btn-primary btn-block" (click)="redirectHome()">กลับหน้าหลัก</button>
            </div>
        </div>
    </div>
</div>