import { Component } from '@angular/core';

@Component({
  selector: 'app-order-cutting-cane-list',
  standalone: true,
  imports: [],
  templateUrl: './order-cutting-cane-list.component.html',
  styleUrl: './order-cutting-cane-list.component.css'
})
export class OrderCuttingCaneListComponent {

}
