<div class="main-dashboard-container">
    <div class="container">
        
        <div class="row">
            <div class="col-md-12">
                <div class="card box-objective-send-cane">
                    <div class="card-body">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-6 p-0">
                                    <div class="text-center p-target">เป้าหมายส่งอ้อย 67/68</div>
                                    <div class="box-hc-pie">
                                        <highcharts-chart 
                                        [Highcharts]="Highcharts"
                                        [options]="chartOptions"
                                        >
                                        </highcharts-chart>
                                    </div>
                                </div>
               
                                <div class="col-6">
                                    <div class="box-list-box-data-pie">
                                        <div class="box-data-pie">
                                            <div class="data-pie">
                                                <span class="p-data-pie">อ้อยสด</span>
                                                <span class="p-data-pie">78%</span>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar bg-success" style="width: 78%;" role="progressbar"aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="box-data-pie">
                                            <div class="data-pie">
                                                <span class="p-data-pie">อ้อยไฟไหม้</span>
                                                <span class="p-data-pie">12%</span>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar bg-danger" style="width: 12%;" role="progressbar"aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="box-data-pie">
                                            <div class="data-pie">
                                                <span class="p-data-pie">อื่นๆ</span>
                                                <span class="p-data-pie">10%</span>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar bg-blue" style="width: 10%;" role="progressbar"aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>  
                                    </div>                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="card box-Daily-send-cane">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="text-left p-Daily">ปริมาณรายวัน</div>
                                <div class="box-hc-bar">
                                    <highcharts-chart 
                                    [Highcharts]="Highcharts"
                                    [options]="columnChartOptions"
                                    >
                                    </highcharts-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body" style="background-color: #fff;">
                        <div class="row">
                            <div class="col-12">
                                <div class="p-total">ภาพรวมหีบอ้อย</div>
                                <div class="text-left p-Daily"><span class="p-day-all">15</span>วัน</div>
                                <div class="box-hc-line">
                                    <highcharts-chart 
                                    [Highcharts]="Highcharts"
                                    [options]="lineChartOptions"
                                    style="width: 100%; height: 140px; display: block;">
                                  </highcharts-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
