<div class="container">
    <div class="box-fullcalendar">
        <full-calendar [options]="calendarOptions"></full-calendar>
    </div>
    <div class="box-dot">
        <span class="dot-blue"></span> รอขนส่ง <span class="dot-green"></span> เสร็จสิ้น
    </div>
    <div class="row mt-4">
        <div class="col-md-6">
            <!-- <a href="order-cutting-cane-detail">
                <div class="box-data-order-cutting">
                <div class="row">
                    <div class="col text-start p-header"><span class="dot-blue"></span> 14:00 - 15:00</div>
                    <div class="col text-end p-header"></div>
                </div>
                    <div class="row">
                        <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                        <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                    </div>
                    <div class="row">
                        <div class="col text-start p-lable">ผู้รับเหมา : สมพงษ์</div>
                        <div class="col text-end p-lable">รอบที่ 10 คิวที่ 100</div>
                    </div>
                    <div class="row">
                        <div class="col-8 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-truck-cane.svg">88-8892</div>
                        <div class="col text-end p-lable"></div>
                    </div>
                </div>
            </a> -->
        </div>
        <div class="col-md-6">
        <!-- <a href="order-cutting-cane-detail">
            <div class="box-data-order-cutting">
                <div class="row">
                <div class="col text-start p-header"><span class="dot-green"></span> 14:00 - 15:00</div>
                <div class="col text-end p-header"></div>
            </div>
                <div class="row">
                    <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                    <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                </div>
                <div class="row">
                    <div class="col text-start p-lable">ผู้รับเหมา : สมพงษ์</div>
                    <div class="col text-end p-lable">รอบที่ 10 คิวที่ 100</div>
                </div>
                <div class="row">
                    <div class="col-8 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-truck-cane.svg">88-8892</div>
                    <div class="col text-end p-lable"></div>
                </div>
            </div>
        </a> -->
        </div>
    </div>
</div>
  