<div class="main-container-htc">
    <div class="container">
        <ul class="nav nav-pills mt-3 mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ระหว่างดำเนินการ</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">เสร็จสิ้น</button>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="row">
                    <div class="col-md-6">
                        <!-- <a href="order-cutting-cane-detail">
                            <div class="box-data-history">
                                <div class="row">
                                    <div class="col text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                    <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                                </div>
                                <div class="row">
                                    <div class="col text-start p-lable">ผู้รับเหมา : สมพงษ์</div>
                                    <div class="col text-end p-lable">รอบที่ 20 , คิวที่180</div>
                                </div>
                                <div class="row">
                                    <div class="col text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-truck-cane.svg">88-8892</div>
                                    <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024 <img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                </div>
                            </div>
                        </a> -->
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="row">
                    <div class="col-md-6">
                        <!-- <a href="order-cutting-cane-detail">
                            <div class="box-data-history">
                                <div class="row">
                                    <div class="col text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                    <div class="col text-end p-header">18.57 ตัน</div>
                                </div>
                                <div class="row">
                                    <div class="col text-start p-lable">ผู้รับเหมา : สมพงษ์</div>
                                    <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024</div>
                                </div>
                                <div class="row">
                                    <div class="col text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-truck-cane.svg">88-8892</div>
                                    <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                </div>
                            </div>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>