import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { HighchartsChartModule } from 'highcharts-angular';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [HighchartsChartModule],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  Highcharts: typeof Highcharts = Highcharts;
  primaryColor: string = '#001DFF';

  ngOnInit(): void {
    this.primaryColor = (Highcharts.getOptions().colors?.[0] as string) ?? '#001DFF';

    // ใช้ฟังก์ชันอัพเดตเพื่อให้กราฟรีเฟรช
    setTimeout(() => {
      Highcharts.charts.forEach(chart => {
        if (chart) chart.reflow();
      });
    }, 0);
  }


  ngAfterViewInit(): void {
    // Reflow all Highcharts instances after the view initializes to ensure correct display
    setTimeout(() => {
      Highcharts.charts.forEach((chart) => chart?.reflow());
    }, 100); // Add slight delay to ensure charts are reflowed after DOM is fully ready
  }

  // Configuration for solid gauge chart
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'solidgauge',
      height: '180px',
      backgroundColor: 'transparent'
    },
    title: { text: '' },
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: []
    },
    tooltip: { enabled: false },
    yAxis: {
      min: 0,
      max: 2000,
      lineWidth: 0,
      tickWidth: 0,
      tickPositions: [],
      gridLineWidth: 0,
      labels: { enabled: false },
    },
    plotOptions: {
      solidgauge: {
        linecap: 'round',
        dataLabels: {
          y: -10,
          borderWidth: 0,
          useHTML: true,
          format: `
            <div style="text-align: center;">
              <div style="font-size:24px; font-weight:700; color:#404040;">{y} ตัน</div>
              <div style="font-size:12px; color:#4C6EF5; font-weight:700;">เป้าหมาย</div>
              <div style="font-size:12px; color:#4C6EF5; font-weight:700;">2,000 ตัน</div>
            </div>
          `,
        }
      }
    },
    series: [
      {
        // Series สำหรับแถบสีเทาอ่อนพื้นหลัง
        type: 'solidgauge',
        name: 'Background',
        data: [{ y: 2000 }],
        innerRadius: '60%', // ปรับขนาดวงในให้พอดี
        radius: '100%', // ปรับขนาดวงนอกให้พอดี
        color: 'rgba(200, 200, 200, 0.3)', // สีเทาอ่อนจาง
        dataLabels: { enabled: false },
        enableMouseTracking: false,
        rounded: true // ใช้ rounded เพื่อให้ปลายโค้ง
      },
      {
        // Series สำหรับแถบสีน้ำเงิน
        type: 'solidgauge',
        name: 'Completion',
        data: [{ y: 950 }],
        innerRadius: '60%', // ควรใช้ค่าเดียวกับแถบพื้นหลัง
        radius: '100%', // ควรใช้ค่าเดียวกับแถบพื้นหลัง
        color: '#4C6EF5', // สีน้ำเงินของแถบหลัก
        dataLabels: { enabled: true },
        rounded: true
      }
    ]
  };


  // Configuration for column chart
  columnChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: '290px',
      backgroundColor: '#FAFAFA'
    },
    title: { text: '' },
    xAxis: { categories: ['อา', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'] },
    yAxis: { title: { text: '' } },
    series: [
      {
        type: 'column',
        name: 'เดือนที่ผ่านมา',
        data: [120, 180, 90, 170, 80, 190, 85],
        color: '#8ADEFD'
      },
      {
        type: 'column',
        name: 'ปัจจุบัน',
        data: [150, 200, 100, 200, 100, 200, 100],
        color: '#4C6EF5'
      }
    ]
  };

  // Configuration for line chart
  lineChartOptions: Highcharts.Options = {
    chart: {
      type: 'area',
      height: '192px',
    },
    title: { text: '' },
    xAxis: { categories: ['10', '11', '12', '13', '14', '15'] },
    yAxis: { visible: false, min: 0 },
    tooltip: { shared: true, valueSuffix: ' หน่วย' },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#4C6EF5'],
            [1, 'rgba(177, 185, 248, 0)']
          ]
        },
        marker: {
          radius: 5,
          fillColor: '#001DFF',
        },
        lineWidth: 2,
        states: { hover: { lineWidth: 3 } },
        threshold: null,
      }
    },
    series: [{
      type: 'area',
      name: 'ยอดขาย',
      data: [4200, 4500, 4400, 4500, 4600, 4700],
      color: '#001DFF',
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 6,
        fillColor: '#001DFF',
      },
      dataLabels: {
        enabled: true,
        format: '{y}',
        style: { fontSize: '12px', fontWeight: 'bold' }
      }
    }]
  };
}
