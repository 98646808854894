import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HomeComponent } from './home/home.component';
import { LoginConfirmComponent } from './login-confirm/login-confirm.component';
import { LoginConfirmInfoComponent } from './login-confirm-info/login-confirm-info.component';
import { LoginConfirmSuccessComponent } from './login-confirm-success/login-confirm-success.component';
import { LoginConfirmPdpaComponent } from './login-confirm-pdpa/login-confirm-pdpa.component';
import { OrderCuttingCaneComponent } from './order-cutting-cane/order-cutting-cane.component';
import { OrderCuttingCaneDetailComponent } from './order-cutting-cane-detail/order-cutting-cane-detail.component';
import { HistoryTransportCaneComponent } from './history-transport-cane/history-transport-cane.component';
import { OrderCuttingCaneListComponent } from './order-cutting-cane-list/order-cutting-cane-list.component';
import { QToWeightInCaneComponent } from './q-to-weight-in-cane/q-to-weight-in-cane.component';
import { FullcalendarCaneComponent } from './fullcalendar-cane/fullcalendar-cane.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ErrorViewComponent } from './error-view/error-view.component';


export const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent, // ใช้ Layout หลัก
        children: [
            { path: 'home', component: HomeComponent,data: { title: 'หน้าแรก' }  },
            { path: 'history-transport-cane', component: HistoryTransportCaneComponent,data: { title: 'ประวัติการขนส่งอ้อย' }  },
            {
                path: 'order-cutting-cane-list', 
                component: OrderCuttingCaneListComponent,
                data: { title: 'รายการใบสั่งตัดอ้อย' }
            },
            {
                path: 'q-to-weight-in-cane', 
                component: QToWeightInCaneComponent,
                data: { title: 'รายการคิว' }
            },
            {
                path: 'order-cutting-cane', 
                component: OrderCuttingCaneComponent,
                data: { title: 'ยืนยันการขนส่งอ้อยเข้าโรงงาน' }
            },
            {
                path: 'order-cutting-cane-detail', 
                component: OrderCuttingCaneDetailComponent,
                data: { title: 'ข้อมูลการขนส่งอ้อยเข้าโรงงาน' }
            },
            {
                path: 'fullcalendar', 
                component: FullcalendarCaneComponent,
                data: { title: 'ปฏิทินการส่งอ้อย' }
            },
            {
                path: 'dashboard', 
                component: DashboardComponent,
                data: { title: 'รายงานเป้าหมายอ้อยเข้าหีบ' }
            },
            { path: '', redirectTo: 'home', pathMatch: 'full' } // เริ่มต้นที่ home
        ]
    },
    {
        path: 'login', 
        component: LoginComponent,
        data: { title: 'ยืนยันเบอร์โทรศัพท์' }
    },
    {
        path: 'login-confirm', 
        component: LoginConfirmComponent,
        data: { title: 'ยืนยันเบอร์โทรศัพท์' }
    },
    {
        path: 'login-confirm-info', 
        component: LoginConfirmInfoComponent,
        data: { title: 'ยืนยันข้อมูลส่วนตัว' }
    },
    {
        path: 'login-confirm-success', 
        component: LoginConfirmSuccessComponent,
        data: { title: 'ลงทะเบียนสำเร็จ' }
    },
    {
        path: 'login-confirm-pdpa', 
        component: LoginConfirmPdpaComponent,
        data: { title: 'Terms & Conditions' }
    },  
    {
        path: 'error-view', 
        component: ErrorViewComponent,
        data: { title: 'พบข้อผิดพลาด' }
    },   
    { path: '**', redirectTo: 'login' } // หากหาเส้นทางไม่เจอให้ไปที่ login
];