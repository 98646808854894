import { Component } from '@angular/core';

@Component({
  selector: 'app-history-transport-cane',
  standalone: true,
  imports: [],
  templateUrl: './history-transport-cane.component.html',
  styleUrl: './history-transport-cane.component.css'
})
export class HistoryTransportCaneComponent {

}
