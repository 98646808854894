import { Component } from '@angular/core';
import { Router } from '@angular/router';
import liff from '@line/liff';

@Component({
  selector: 'app-login-confirm-success',
  standalone: true,
  templateUrl: './login-confirm-success.component.html',
  styleUrls: ['./login-confirm-success.component.css']
})
export class LoginConfirmSuccessComponent {
  constructor(private router: Router) {}

  async navigateToHome() {
    try {
      if (navigator.userAgent.includes("Line")) {
        // ตรวจสอบว่า LIFF ถูกโหลดหรือไม่
        // await liff.init({ liffId: 'YOUR_LIFF_ID' }); // แทนที่ YOUR_LIFF_ID ด้วย LIFF ID ของคุณ
        liff.closeWindow(); // ปิดหน้าต่าง LINE Browser
      } else {
       alert('กรุณาคลิกกากบาทเพื่อปิดหน้าเว็ป')
      }
    } catch (error) {
      console.error('เกิดข้อผิดพลาด:', error);
      alert('ไม่สามารถปิดหน้าต่างได้');
    }
  }
}
