import { CommonModule } from '@angular/common';
import { Component, AfterViewInit, Inject, PLATFORM_ID, ViewChild, ElementRef, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';


declare var longdo: any;
declare var bootstrap: any;

@Component({
  selector: 'app-order-cutting-cane',
  standalone: true,
  imports: [CommonModule,FormsModule],
  templateUrl: './order-cutting-cane.component.html',
  styleUrls: ['./order-cutting-cane.component.css'],
})
export class OrderCuttingCaneComponent implements AfterViewInit, OnInit {
  @ViewChild('map', { static: false }) mapElement!: ElementRef;
  map: any;
  id: string | null = null; // กำหนดตัวแปรสำหรับเก็บค่า id จาก URL

  faccode: string | null = null;
  harvestNo: string | null = null;
  data: any;
  bpName: string | null = null;
  bpTelephone: string | null = null;
  queueName: string | null = null;
  queueNo: string | null = null;
  dlvCode: string | null = null;
  plotString: string | null = null;
  plot_location: string | null = null;
  send_to: string | null = null;
  estimate_to_factory: string | null = null;
  extimate_to_wt: string | null = null;
  in_area: boolean = false;
  harvest_photo: string | null = null;
  msgError: string | null = null;
  number_plotString: string | null = null;

  currentLatitude: number | null = null;
  currentLongitude: number | null = null;


  dateNow: string | null = null;
  timeNow: string | null = null;

  token: string | null = null;
  // bp_role_data: string | null = null;
  
  show_img: string | null = null;


  confirm_time: string | null = null;
  confirm_status: boolean = false;

  bg_status_css_harvestNo: string | null = 'bg-success';
  bg_status_css_harvestNo_modal: string | null = 'bg-green';
  btn_css: string | null = null;
  map_css: string | null = null;
  bg_time_out: string | null = 'bg-time-out';
  inputtime_readonly: boolean = true;

  bp_code: any;
  legacy_bp_type: any;
  bp_role_data: { bp_code: string; legacy_bp_type: string; company: string; fullname: string; }[] = [];
  bpCodeArray: string[] = [];
  legacyBpTypeArray: string[] = [];

  @ViewChild('txtTimeInput') txtTimeInput!: ElementRef;

  isLoading: boolean = false;
  isLoadingAPIGET: boolean = false;
  harvest_photo_css: string | null = 'd-none';

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private route: ActivatedRoute // เพิ่ม ActivatedRoute เพื่อดึงพารามิเตอร์จากเส้นทาง
  ) {}

  ngOnInit(): void {
    
    this.route.queryParamMap.subscribe(params => {
      this.faccode = params.get('faccode');
      console.log("faccode:", this.faccode);
      this.harvestNo = params.get('harvestNo');
      console.log("harvestNo:", this.harvestNo);
      this.show_img = params.get('show_img');
      console.log("show_img:", this.show_img);
    });
  



    this.dateNow = this.getCurrentThaiDate();
    this.getCurrentTime();

    this.getCurrentLocation();


    const modalAlertLocationElement = document.getElementById('modal_alert_location');
    if (modalAlertLocationElement) {
      modalAlertLocationElement.addEventListener('hidden.bs.modal', () => {
        window.location.reload();
      });
    }

    if(this.show_img == 'Y'){
      this.showPopup();
    }
  
    
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loadLongdoMapScript().catch(error => {
        console.error('Failed to load Longdo Map script:', error);
      });
    }
  }

  loadLongdoMapScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src="https://api.longdo.com/map/?key=10c3e049f8dbd1866c40255eac57bd09"]')) {
        resolve();
        return;
      }

      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://api.longdo.com/map/?key=10c3e049f8dbd1866c40255eac57bd09';
      scriptElement.async = true;
      scriptElement.onload = () => {
        console.log('Longdo Map script loaded successfully');
        resolve();
      };
      scriptElement.onerror = () => reject(new Error('Longdo Map script failed to load.'));
      document.body.appendChild(scriptElement);
    });
  }

  initializeMap(): void {
    console.log('Initializing map...');
    if (typeof longdo === 'undefined' || !this.plot_location) {
      console.error('Longdo Map library is not loaded or plot_location is missing.');
      return;
    }

    this.map = new longdo.Map({
      placeholder: this.mapElement.nativeElement
    });

    const [lat, lon] = this.plot_location.split(",").map(coord => parseFloat(coord.trim()));
    this.map.location({ lon, lat }, true);
    this.map.zoom(14);
    console.log('Map initialized with location:', { lat, lon });
  }


  showRoute(): void {
    if (this.plot_location) {
      const [lat, lon] = this.plot_location.split(",").map(coord => parseFloat(coord.trim()));
  
      // Construct the URL for Google Maps with origin as current location
      const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${this.currentLatitude},${this.currentLongitude}&destination=${lat},${lon}&travelmode=driving`;
  
      // Open the URL in a new browser tab
      window.open(mapUrl, '_blank');
    } else {
      console.error('Plot location is not defined.');
    }
  }
  

  openSuccessModal() {
    if (this.isLoading) return; 
    this.isLoading = true;

    this.saveHarvestOrderConfirm();

  
    
  }

  closeModal(): void {
    const modalConfirmElement = document.getElementById('modal_confrim');
    if (modalConfirmElement) {
      const modalConfirmInstance = bootstrap.Modal.getInstance(modalConfirmElement);
      if (modalConfirmInstance) {
        modalConfirmInstance.hide();
      } else {
        console.error('Modal instance not found, please ensure the modal is already opened before closing.');
      }
    } else {
      console.error('Modal element not found!');
    }
  }

  isPopupVisible = false;

  showPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  shareImage() {
    if (navigator.share) {
      if (this.harvest_photo) {
        navigator.share({
          title: 'ใบสั่งตัด',
          text: 'แชร์ภาพใบสั่งตัด',
          url: this.harvest_photo
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing:', error));
      } else {
        console.error('No URL to share.');
        alert('ไม่มี URL สำหรับการแชร์');
      }
    } else {
      console.error('Web Share API is not supported in this browser.');
      alert('การแชร์ไม่รองรับในเบราว์เซอร์นี้');
    }
  }

  getHarvestOrderConfirm(){
  
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
      console.log(this.token);

      this.bpCodeArray = this.bp_role_data.map(item => item.bp_code);
      this.legacyBpTypeArray = this.bp_role_data.map(item => item.legacy_bp_type);

      console.log(this.bpCodeArray); // แสดงค่าของ bp_code
      console.log(this.legacyBpTypeArray); // แสดงค่าของ legacy_bp_type

     
    }
    const bpCodeHeader = this.bpCodeArray.join(','); // เช่น "101000,K106000"
    const legacyBpTypeHeader = this.legacyBpTypeArray.join(','); // เช่น "QUOTA,CONTRACTOR"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token), 
      'bp_code': bpCodeHeader,
      'legacy_bp_type': legacyBpTypeHeader
    });
    const body = { harvestNo: this.harvestNo , faccode: this.faccode , lat: this.currentLatitude ,long: this.currentLongitude};
    this.apiService.post('getHarvestOrderConfirm',body,headers).subscribe(
      data => {

        this.data = data;
        this.confirm_status =  this.data.confirm_status;
        this.confirm_time   =  this.data.confirm_time;
    
        if(this.data.code == 1){
          console.log(this.data);
          this.bpName = this.data.data.bpName??'-';
          this.bpTelephone = this.data.data.bpTelephone??'-';
          this.queueName = this.data.data.queueName??'-';
          this.queueNo = this.data.data.queueNo??'-';
          this.dlvCode = this.data.data.dlvCode??'-';
          this.plotString = this.data.data.plotString??'-';
          this.plot_location = this.data.data.plot_location??'-';
          this.send_to = this.data.data.send_to??'-';
          this.estimate_to_factory = this.data.data.estimate_to_factory??'-';
          this.extimate_to_wt = this.data.data.extimate_to_wt??'-';
          this.in_area = this.data.data.in_area;
          this.harvest_photo = this.data.data.harvest_photo;
          this.number_plotString = this.plotString??'-';

          const hasContractor = this.legacyBpTypeArray.includes("CONTRACTOR");
          if (!hasContractor) {
            console.log("CONTRACTOR is not present in legacyBpTypeArray");
            if(this.confirm_status){
              this.estimate_to_factory = '-';
              this.extimate_to_wt = '-';
              this.dateNow = '-';
              this.timeNow = '--:--';
              this.btn_css = 'd-none';
            }
            if(!this.in_area){
              this.plotString = this.data.data.plotString+' (อยู่นอกพื้นที่)'??'-';
              this.bg_status_css_harvestNo = 'bg-danger';
              this.bg_time_out = 'bg-time-out-outline';
              this.inputtime_readonly = false;
              this.bg_status_css_harvestNo_modal = 'bg-danger';
            }
          }else{
            if(!this.in_area){
              this.plotString = this.data.data.plotString+' (อยู่นอกพื้นที่)'??'-';
              this.bg_status_css_harvestNo = 'bg-danger';
              this.bg_time_out = 'bg-time-out-outline';
              this.inputtime_readonly = false;
              this.bg_status_css_harvestNo_modal = 'bg-danger';
            }
          }

          if(this.confirm_status){
            this.btn_css = 'd-none';
            this.map_css = 'd-none';
            this.bg_status_css_harvestNo = 'bg-blue';

            this.plotString = this.data.data.plotString??'-';
            this.bg_time_out = '';
            this.harvest_photo_css = '';
          }

         

          this.loadLongdoMapScript().then(() => {
            this.initializeMap();
          }).catch(error => {
            console.error('Failed to initialize map:', error);
          });

         

        }else if(this.data.code == 2){
          const message = this.data.message;
          console.log(message);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('msgError', message);
           }
          this.router.navigate(['/error-view']);
        }

        this.isLoadingAPIGET = true;
        
      },
      error => {
      
        console.error('API call failed', error);
      }
    );
  }

  getCurrentThaiDate(): string {
    const currentDate = new Date();
    const thaiYear = currentDate.getFullYear() + 543;  // แปลงปีเป็น พ.ศ.
    const formattedDate = formatDate(currentDate, 'dd/MM', 'en-US') + '/' + thaiYear; // ฟอร์แมตเป็น dd/mm/yyyy
    return formattedDate;
  }

  getCurrentTime(): void {
    const currentDate = new Date();
    const hours = currentDate.getHours().toString().padStart(2, '0'); // ชั่วโมง
    const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // นาที
    const seconds = currentDate.getSeconds().toString().padStart(2, '0'); // วินาที
    this.timeNow = `${hours}:${minutes}`; // รูปแบบ hh:mm:ss
  }

  saveHarvestOrderConfirm(){
    this.msgError = '';
    const txtTimeInput = this.txtTimeInput.nativeElement.value;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'token': String(this.token)
    });
    const body = {plotString: this.number_plotString , harvestNo: this.harvestNo , faccode: this.faccode , in_area: this.in_area , confirm_time:txtTimeInput};
    this.apiService.post('saveHarvestOrderConfirm',body,headers).subscribe(
      data => {

        this.data = data;
        this.confirm_status =  this.data.confirm_status;
        this.confirm_time   =  this.data.confirm_time;
    
        if(this.data.code == 1){
         


          console.log(this.data);
          this.bpName = this.data.data.bpName;
          this.bpTelephone = this.data.data.bpTelephone;
          this.queueName = this.data.data.queueName;
          this.queueNo = this.data.data.queueNo;
          this.dlvCode = this.data.data.dlvCode;
          this.plotString = this.data.data.plotString;
          this.plot_location = this.data.data.plot_location;
          this.send_to = this.data.data.send_to;
          this.estimate_to_factory = this.data.data.estimate_to_factory;
          this.extimate_to_wt = this.data.data.extimate_to_wt;
          this.in_area = this.data.data.in_area;

          if(this.confirm_status){
            this.btn_css = 'd-none';
            this.bg_status_css_harvestNo = 'bg-blue';
            this.bg_time_out = '';
          }

          // if(this.in_area){
          //   this.plotString = this.data.data.plotString+' (อยู่นอกพื้นที่)';
          //   this.bg_status_css_harvestNo = 'bg-danger';
          //   this.bg_time_out = 'bg-time-out-outline';
          //   this.inputtime_readonly = false;
          //   this.bg_status_css_harvestNo_modal = 'bg-danger';
          // }

          setTimeout(() => {
            this.closeModal();
            const modalConfirmSuccessElement = document.getElementById('modal_confrim_success');
            if (modalConfirmSuccessElement) {
              const modalConfirmSuccess = new bootstrap.Modal(modalConfirmSuccessElement);
              modalConfirmSuccess.show();
            }
            this.isLoading = false;
          }, 3000);
          
        }else{
          const message = this.data.message;
          this.msgError = message;
          this.isLoading = false;
        }
        
      },
      error => {
        this.isLoading = false;
        console.error('API call failed', error);
      }
    );
  }

  callPhoneNumber() {
    window.location.href = `tel:${this.bpTelephone}`;
  }

  getCurrentLocation(): void {
    const isLineBrowser = navigator.userAgent.includes('Line');

   
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.currentLatitude = position.coords.latitude;
          this.currentLongitude = position.coords.longitude;
          console.log(`Current Location: Latitude ${this.currentLatitude}, Longitude ${this.currentLongitude}`);

          this.getHarvestOrderConfirm();
        },
        (error) => {
          console.error('Error retrieving location:', error);
          const modalAlertLocationElement = document.getElementById('modal_alert_location');
          if (modalAlertLocationElement) {
            const modalAlertLocation = new bootstrap.Modal(modalAlertLocationElement);
            modalAlertLocation.show();
          }

          if (isLineBrowser) {
            const modalAlertLocationElement = document.getElementById('modal_alert_location');
            if (modalAlertLocationElement) {
              const modalAlertLocation = new bootstrap.Modal(modalAlertLocationElement);
              modalAlertLocation.show();
            }
          }
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');

      const modalAlertLocationElement = document.getElementById('modal_alert_location');
      if (modalAlertLocationElement) {
        const modalAlertLocation = new bootstrap.Modal(modalAlertLocationElement);
        modalAlertLocation.show();
      }

      if (isLineBrowser) {
        const modalAlertLocationElement = document.getElementById('modal_alert_location');
        if (modalAlertLocationElement) {
          const modalAlertLocation = new bootstrap.Modal(modalAlertLocationElement);
          modalAlertLocation.show();
        }
      }
    }
  }
}
