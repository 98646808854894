<div class="container">
    <ul class="nav nav-pills mt-3 mb-3" id="pills-main-tab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ปัจจุบัน</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">คิวของฉัน</button>
        </li>
    </ul>
    <div class="tab-content" id="pills-main-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="row">
                <div class="col-md-6">
                    <!-- <a href="order-cutting-cane-detail">
                        <div class="box-data-order-cutting">
                            <div class="row">
                                <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-truck-cane.svg">คิวควบคุม รถเล็ก</div>
                                <div class="col text-end p-header">รอบที่ : 1</div>
                            </div>
                            <div class="row">
                                <div class="col text-start p-lable">ประกาศเรียกเมื่อ</div>
                                <div class="col text-end p-lable">คิวที่ : 200</div>
                            </div>
                            <div class="row">
                                <div class="col-8 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024 <img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                <div class="col text-end p-lable">ถึง : 210</div>
                            </div>
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="row">
                <div class="col-md-6">
                    <!-- <a href="order-cutting-cane-detail">
                        <div class="box-data-order-cutting">
                            <div class="row">
                                <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                <div class="col-5 text-end p-header pl-0">คิวควบคุม_พ่วง</div>
                            </div>
                            <div class="row">
                                <div class="col-8 text-start p-lable">ชาวไร่ : ประสงค์  อ้อยสวยงาม</div>
                                <div class="col text-end p-lable">รอบที่ : 100</div>
                            </div>
                            <div class="row">
                                <div class="col-8 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024 <img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                <div class="col-4 text-end p-lable">คิวที่ : 10</div>
                            </div>
                        </div>
                    </a> -->
                </div>
            </div>
        </div>
    </div>
</div>
