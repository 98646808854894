<div class="container">
    <ul class="nav nav-pills mt-3 mb-3" id="pills-main-tab" role="tablist">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">ผู้รับเหมา</button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">ชาวไร่</button>
        </li>
    </ul>
    <div class="tab-content" id="pills-main-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <ul class="nav nav-status mt-3 mb-3" id="status-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="status-waiting-tab" data-bs-toggle="pill" data-bs-target="#status-waiting" type="button" role="tab" aria-controls="status-waiting" aria-selected="true">รอดำเนินการ</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="status-transport-tab" data-bs-toggle="pill" data-bs-target="#status-transport" type="button" role="tab" aria-controls="status-transport" aria-selected="false">ระหว่างขนส่ง</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="status-success-tab" data-bs-toggle="pill" data-bs-target="#status-success" type="button" role="tab" aria-controls="status-success" aria-selected="false">ขนส่งเสร็จสิ้น</button>
                </li>
            </ul>
            <div class="tab-content" id="status-tabContent">
                <div class="tab-pane fade show active" id="status-waiting" role="tabpanel" aria-labelledby="status-waiting-tab">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- <a href="order-cutting-cane">
                                <div class="box-data-order-cutting">
                                    <div class="row">
                                        <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                        <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-7 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-mark-blue.svg">หนองคอนไทย หมู่ 7</div>
                                        <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-start p-lable">ชาวไร่ :สมพงษ์ สุขใจ</div>
                                        <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                    </div>
                                </div>
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="status-transport" role="tabpanel" aria-labelledby="status-transport-tab">
                    <!-- เนื้อหาในสถานะระหว่างขนส่ง -->
                </div>
                <div class="tab-pane fade" id="status-success" role="tabpanel" aria-labelledby="status-success-tab">
                    <!-- เนื้อหาในสถานะขนส่งเสร็จสิ้น -->
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <ul class="nav nav-status mt-3 mb-3" id="status-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="status-waiting-tab" data-bs-toggle="pill" data-bs-target="#status-waiting" type="button" role="tab" aria-controls="status-waiting" aria-selected="true">รอดำเนินการ</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="status-transport-tab" data-bs-toggle="pill" data-bs-target="#status-transport" type="button" role="tab" aria-controls="status-transport" aria-selected="false">ระหว่างขนส่ง</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="status-success-tab" data-bs-toggle="pill" data-bs-target="#status-success" type="button" role="tab" aria-controls="status-success" aria-selected="false">ขนส่งเสร็จสิ้น</button>
                </li>
            </ul>
            <div class="tab-content" id="status-tabContent">
                <div class="tab-pane fade show active" id="status-waiting" role="tabpanel" aria-labelledby="status-waiting-tab">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- <a href="order-cutting-cane">
                                <div class="box-data-order-cutting">
                                    <div class="row">
                                        <div class="col-7 text-start p-header"><img class="img-icon-cane" src="image/icon/icon-cane.svg">แปลง 110506212</div>
                                        <div class="col text-end p-header">คิวควบคุม_พ่วง</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-mark-blue.svg">หนองคอนไทย หมู่ 7</div>
                                        <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">21/10/2024</div>
                                    </div>
                                    <div class="row">
                                        <div class="col text-start p-lable">ชาวไร่ :สมพงษ์ สุขใจ</div>
                                        <div class="col text-end p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">18:00</div>
                                    </div>
                                </div>
                            </a> -->
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="status-transport" role="tabpanel" aria-labelledby="status-transport-tab">
                    <!-- เนื้อหาในสถานะระหว่างขนส่ง -->
                </div>
                <div class="tab-pane fade" id="status-success" role="tabpanel" aria-labelledby="status-success-tab">
                    <!-- เนื้อหาในสถานะขนส่งเสร็จสิ้น -->
                </div>
            </div>
        </div>
    </div>
</div>
