<div class="login-container">
    <div class="container">
        <div class="login-content text-center">
            <div class="box-login">
                <div class="row justify-content-center">
                    <div class="box-img-logo">
                        <img class="img-logo" src="image/icon/icon-logo-mitrphol.svg">
                        <img class="img-icon-success" src="image/icon/icon-success-primary.svg">
                    </div>
                    <p class="login-header">ยืนยันตัวตนสำเร็จ</p>
                    <div class="col-md-6">
                        <form class="login-form">
                            <a href="javascript:void(0)" class="btn" (click)="navigateToHome()">เริ่มใช้งาน</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  