<div class="login-container">
    <div class="container">
        <div class="login-content text-center">
            <div class="box-otp-sms">
                <p class="p-header">รหัสผ่านแบบใช้ครั้งเดียว (OTP-SMS)<br>จะส่งไปยังเบอร์</p>
                <p class="p-telephone" id="phone">{{ phone }}</p>
                <div style="padding: 10px 0px;border-bottom: 1px solid #F6F6F6;"></div>
                <div class="row form-otp justify-content-center">
                    <div class="col-md-6">
                        <div class="d-inline-flex w-100 justify-content-center align-items-center">
                            <div class="p-num-otp">รหัส OTP</div>
                            <div class="position-relative">
                                <input #numberOtp type="text" class="form-control input-with-icon" id="number_otp" placeholder="xxxxxx" maxlength="6" (input)="numberOtp.value.length === 6 ? validateOTP() : null" [ngClass]="{'error': isOtpInvalid}">
                                <i *ngIf="isLoading" class="loading-icon"></i>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div *ngIf="isOtpInvalid" class="p-opt-fail">รหัส OTP ไม่ถูกต้อง<br>กรุณาตรวจสอบอีกครั้ง</div>
                <a href="javascript:void(0)" class="a-re-req-otp" (click)="requestOTP()">"ขอรหัส OTP อีกครั้ง"</a>
            </div>
            <div class="btn-back">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <a href="login" class="btn">ย้อนกลับ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  