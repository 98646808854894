<div class="main-container-home">
    <div class="container">
        <div class="div-hearder">สวัสดี, คุณสมพงษ์</div>

        <div class="row">
            <div class="col-7">
                <a href="order-cutting-cane-list">
                    <div class="box-order-cutting">
                        <div class="row">
                            <div class="col-6 p-0">
                                <div class="box-order-cutting-list">
                                    <p class="p-header-order-cutting">ใบสั่งตัด</p>
                                    <p class="p-order-cutting-list">0 รายการ</p>
                                </div>
                            </div>
                            <div class="col-6">
                                <img class="img-order-cutting-list" src="image/icon/icon-order-cutting.svg">
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-5 pl-0">
                <a href="fullcalendar">
                    <div class="box-fcld-cane">
                        <img class="img-order-cutting-cld" src="image/icon/icon-order-cutting-cld.svg">
                        <p class="p-fcld-send-cone">ปฏิทินส่งอ้อย</p>
                    </div>
                </a>
            </div>
        </div>

        <div class="row" style="margin-top: 15px;">
            <div class="col-6 pr-0">
                <a href="history-transport-cane">
                    <div class="box-history-cutting">
                        <div class="row">
                            <div class="col">
                                <div class="box-history-cutting-cane">
                                    <p class="p-header-history-cutting-cane">ประวัติส่งอ้อย</p>
                                    <p class="p-history-cutting-cane-list">0 รายการ</p>
                                </div>
                            </div>
                            <img class="icon-history-cane" src="image/icon/icon-history-cane.svg">
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-6">
                <a href="q-to-weight-in-cane">
                    <div class="box-q-weight-in">
                        <p class="p-q-weight-in">คิวรถของฉัน</p>
                        <div class="border-yellow mt-2"></div>
                    </div>
                </a>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col text-start">
                <span class="p-history-send-cane">ประวัติการส่งอ้อย</span>
            </div>
            <div class="col text-end">
                <a href="history-transport-cane" class="a-all-history-send-cane">ดูทั้งหมด <img src="image/icon/icon-click-next.svg" class="icon-click-next"></a>
            </div>
        </div>


        <div class="row mt-4">
            <div class="col-md-6">
                <!-- <a href="order-cutting-cane-detail">
                    <div class="box-history-cutting-cane-list">
                        <div class="row">
                            <div class="col-6 text-start p-lable pr-0"><img class="img-icon-cane" src="image/icon/icon-calendar.svg">วันที่ส่ง: 21/10/2024</div>
                            <div class="col text-end p-lable pl-0">หนองคอนไทย หมู่ 7</div>
                        </div>
                        <div class="row">
                            <div class="col-4 text-start p-lable"><img class="img-icon-cane" src="image/icon/icon-clock.svg">08:00</div>
                            <div class="col-8 text-end p-header">แปลงอ้อย 101156789</div>
                        </div>
                        <div class="row">
                            <div class="col-8 text-start p-lable">ชาวไร่ : ประสงค์  อ้อยสวยงาม</div>
                            <div class="col-4 text-end p-lable"><span class="badge bg-blue">รอดำเนินการ</span></div>
                        </div>
                    </div>
                </a> -->
            </div>
        </div>
    </div>
</div>
