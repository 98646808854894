<div class="login-container">
    <div class="container">
        <div class="login-content text-center">
            <div class="box-login">
                <div class="row justify-content-center">
                    <img class="icon-img-logo" src="image/icon/icon-logo-mitrphol.svg">
                    <p class="login-header">กรอกข้อมูล</p>
                
                    <div class="col-md-6">
                        <form class="login-form">
                            <div class="form-group">
                                <label for="phone" class="form-label">หมายเลขบัตรประชาชน<span class="color-red">*</span></label>
                                <input type="text" class="form-control" id="id_card" #idCardInput placeholder="0 0000 00000 00 0" maxlength="13"  (input)="onIdCardInput()" [ngClass]="{'error': isError}">
                            </div>
                            <div class="form-group">
                                <label for="phone" class="form-label">โรงงาน<span class="color-red">*</span></label>
                                <select class="form-select" id="wh_id" #factorySelect>
                                    <option *ngFor="let factory of factoryList" [value]="factory.code">{{ factory.name }}</option>
                                </select>
                            </div>
                    
                            <button type="submit" class="btn" (click)="onAcceptClick()" [disabled]="!isIdCardComplete || isLoading"><i *ngIf="isLoading" class="loading-icon"></i>ยืนยันตัวตน</button>
                            <small *ngIf="isError">ข้อมูลไม่ถูกต้อง</small>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  