import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';

interface ApiResponse {
  code: number;
  message: string;
  data: {
    html_text: string;
    factoryList: any[];
  };
}

@Component({
  selector: 'app-login-confirm-pdpa',
  standalone: true,
  templateUrl: './login-confirm-pdpa.component.html',
  styleUrls: ['./login-confirm-pdpa.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LoginConfirmPdpaComponent implements OnInit {
  data: any;
  uuid: string | null = null;
  html_text: SafeHtml | null = null;
  factoryList: any;
  isAgreeChecked: boolean = false; // ตัวแปรสำหรับตรวจสอบสถานะของ checkbox
  idCard: string | null = null;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}

  ngOnInit(): void {
    this.idCard = localStorage.getItem('idCard');
    // if(this.idCard){
    //   this.router.navigate(['/login-confirm-success']);
    // }else{
      this.route.queryParamMap.subscribe(params => {
        this.uuid = params.get('uuid');
        console.log("UUID in login-confirm-pdpa:", this.uuid);
        if (isPlatformBrowser(this.platformId)&& this.uuid) {
          localStorage.setItem('uuid', this.uuid);
        }
      });
    // }
   

    this.apiService.get<ApiResponse>('getTermandCondition').subscribe(response => {
      this.data = response;
      console.log(response);
      const decodedHtml = this.decodeHtmlEntities(response.data.html_text);
      this.html_text = this.sanitizer.bypassSecurityTrustHtml(decodedHtml);
      this.factoryList = response.data.factoryList;
      localStorage.setItem('factoryList', JSON.stringify(this.factoryList));
    });


    // const body = { line_user_id: this.uuid };
    // this.apiService.post('registerStatus', body).subscribe(
    //   data => {

    //     this.data = data;

    
    //     if(this.data.code == 1){
    //       const register_status = this.data.register_status;
    //       console.log(register_status);
    //       if(register_status || register_status == 'true'){//กรณีมีข้อมูลลงทะเบียนแล้ว
          
    //         this.router.navigate(['/login-confirm-success']);
    //       }
    //     }else{
      
    //     }
        
    //   },
    //   error => {
      
    //     console.error('API call failed', error);
    //   }
    // );

    
  }

  decodeHtmlEntities(str: string): string {
    return str.replace(/&amp;/g, "&")
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/&quot;/g, '"')
              .replace(/&#039;/g, "'");
  }

  onCheckboxChange(event: Event): void {
    this.isAgreeChecked = (event.target as HTMLInputElement).checked;
  }

  onAcceptClick(): void {
    console.log('ปุ่มถูกคลิกแล้ว');
    this.router.navigate(['/login-confirm-info']);
  }
}
