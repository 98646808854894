import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isPlatformBrowser, CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent  implements OnInit {
  @ViewChild('phoneInput') phoneInput!: ElementRef;

  data: any; // ตัวแปรสำหรับเก็บข้อมูลจาก API
  uuid: string | null = null; // ตัวแปรสำหรับเก็บ uuid
  isPhoneComplete: boolean = false;
  isError: boolean = false;
  msgError: string | null = null;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object // ตรวจสอบ Platform
  ) {}



  ngOnInit(): void {
    
  }

  onAcceptClick(): void {
    console.log('ปุ่มถูกคลิกแล้ว');
    const phone = this.phoneInput.nativeElement.value;
    localStorage.setItem('phone', phone);

    const idCard = localStorage.getItem('idCard');
    console.log(idCard);
    if(idCard && idCard != 'undefined'){
      const body = { phone: phone };
      this.apiService.post('OTPSend', body).subscribe(data => {
        console.log(data);
        this.data = data; // เก็บข้อมูลจาก API
        
        if (isPlatformBrowser(this.platformId)&& this.data.data.otp_token) {
          localStorage.setItem('otp_token', this.data.data.otp_token);
        }
        this.router.navigate(['/login-confirm']);
      });
    }else{
      const body = { phone: phone };
      this.apiService.post('checkLoginbyTelephone', body).subscribe(data => {
        console.log(data);
        this.data = data; // เก็บข้อมูลจาก API
        if(this.data.code == 1){
          if (isPlatformBrowser(this.platformId)) {
            console.log(this.data.data);
            localStorage.setItem('otp_token', this.data.data.otp_token);
            localStorage.setItem('status_relogin', this.data.data.status_relogin);
            // localStorage.setItem('uuid', this.data.data.user_id);
            // localStorage.setItem('idCard', this.data.data.idcard);
          }
          const otp_token = localStorage.getItem('otp_token');
          console.log(otp_token);
          this.router.navigate(['/login-confirm']);
        }else{
          this.isError = true;
          this.msgError = this.data.message;
        }
    
      });
    }
  }


  onPhoneInput(): void {
    const phone = this.phoneInput.nativeElement.value.trim();
    this.isPhoneComplete = phone.length === 10; // เปิดใช้งานปุ่มเมื่อครบ 10 หลัก
  }
}
