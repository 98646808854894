<div class="main-container">
  <div class="">  
    <main class="content">
        <router-outlet></router-outlet>
    </main>
    
    <div class="box-footer" style="padding: 10px;">
        <!-- <nav class="nav nav-pills nav-fill ">
            <a routerLink="/home"  routerLinkActive="active"  id="main-nav-link-home" class="nav-link p-nav-link"><img class="icon-home" src="image/icon/icon-home.svg"><br>หน้าแรก</a>
            <a routerLink="/fullcalendar"  routerLinkActive="active" id="main-nav-link-calendar" class="nav-link p-nav-link"><img class="icon-home" src="image/icon/icon-desk.svg"><br>ปฏิทิน</a>
            <a routerLink="/dashboard"  routerLinkActive="active" id="main-nav-link-dashboard" class="nav-link p-nav-link"><img class="icon-home" src="image/icon/icon-pipe-light.svg"><br>เป้าหมาย</a>
        </nav> -->
        <a href="javascript:void(0)" 
          style="text-decoration: unset; color: #000;" 
          (click)="logout()">ออกจากระบบ</a>
    </div>
  </div>
</div>

