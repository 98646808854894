<div class="login-container">
  <div class="container">
    <div class="row justify-content-center">
      <div class="">
        <div class="terms-title">ข้อกำหนดและเงื่อนไข</div>
        <div class="terms-subtitle">
          นโยบายความเป็นส่วนตัว<br />นโยบายความเป็นส่วนตัวของ มิตรชาวไร่
        </div>

        <div class="terms-content" [innerHTML]="html_text">
          <!-- <div>
            <p style="font-size: 20px; letter-spacing: 1px"></p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >กลุ่มมิตรผลมุ่งมั่นดำเนินธุรกิจภายใต้หลักการกำกับดูแลกิจการที่ดีและโปร่งใสและให้ความสำคัญต่อการคุ้มครองข้อมูลส่วนบุคคล
                  เพื่อให้บริษัทในกลุ่มมิตรผลมีการเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลที่ถูกต้องและสอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                  พ.ศ. 2562
                  จึงกำหนดนโยบายคุ้มครองข้อมูลส่วนบุคคลของกลุ่มมิตรผลดังต่อไปนี้</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong>1. ข้อมูลส่วนบุคคล</strong></span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >ข้อมูลส่วนบุคคล หมายถึง
                  ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้
                  ไม่ว่าทางตรงหรือทางอ้อม
                  แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรม</span
                ></span
              >
            </p>

            <p>
              <br />
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong
                    >2.
                    วัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลของกลุ่มมิตรผล</strong
                  ></span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >2.1 เพื่อใช้ในการติดต่อ ยืนยัน
                  หรือระบุตัวบุคคลของเจ้าของข้อมูลส่วนบุคคล</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >2.2 เพื่อประโยชน์ในการบริหารจัดการของกลุ่มมิตรผล</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong>3. การเก็บรวบรวมข้อมูลส่วนบุคคล</strong><br />
                  กลุ่มมิตรผลจะเก็บรวบรวม
                  และเก็บรักษาข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลด้วยวิธีการที่ชอบด้วยกฎหมายและเป็นธรรม
                  เพียงเท่าที่จำเป็นและเหมาะสมต่อวัตถุประสงค์และเป็นประโยชน์ต่อเจ้าของข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด&nbsp;<br />
                  ทั้งนี้
                  กลุ่มมิตรผลอาจได้รับข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลผ่าน
                  2 ช่องทาง ได้แก่</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >(1) จากเจ้าของข้อมูลส่วนบุคคลโดยตรง ผ่านทางเอกสารสมัครงาน
                  ใบสอบถาม การยื่นคำร้องขอ การสมัครใช้บริการ
                  การโต้ตอบทางจดหมายอิเล็กทรอนิกส์ หรือช่องทางอื่นๆ
                  ระหว่างกลุ่มมิตรผลกับเจ้าของข้อมูลส่วนบุคคล</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >(2) จากบุคคลที่สาม ผ่านทางจดหมายอิเล็กทรอนิกส์
                  การได้รับแจ้งทางโทรศัพท์ หรือได้รับเป็นเอกสารหรือช่องทางอื่นๆ
                  &nbsp;</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong>4. ข้อจำกัดการใช้งานข้อมูลส่วนบุคคล</strong></span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >4.1
                  กลุ่มมิตรผลจะใช้ข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลเท่าที่จำเป็นและสอดคล้องกับวัตถุประสงค์ของการรวบรวมและจัดเก็บข้อมูลเท่านั้น</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >4.2
                  กลุ่มมิตรผลจะไม่นำข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลไปเปิดเผยต่อบุคคลภายนอกโดยปราศจากความยินยอมของเจ้าของข้อมูลส่วนบุคคล
                  เว้นแต่เป็นไปตามที่กฎหมายกำหนด &nbsp;</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong
                    >5. การเก็บรักษาข้อมูลส่วนบุคคล
                    และระยะเวลาในการจัดเก็บข้อมูล</strong
                  ><br />
                  กลุ่มมิตรผลจะจัดเก็บข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลดังนี้</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >(1) จัดเก็บในรูปแบบ Soft Copy และ Hard Copy
                  ตามมาตรฐานการรักษาความปลอดภัยของข้อมูลส่วนบุคคลตามกฎหมาย</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >(2)
                  จัดเก็บตามระยะเวลาที่จำเป็นเพื่อประโยชน์ในการใช้งานข้อมูลส่วนบุคคลตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคล
                  เว้นแต่ในกรณีที่ต้องเก็บรักษาข้อมูลส่วนบุคคลไว้ตามที่กฎหมายกำหนด
                  เมื่อพ้นระยะเวลาจัดเก็บแล้ว กลุ่มมิตรผลจะดำเนินการทำลายข้อมูล
                  ส่วนบุคคลดังกล่าวด้วยวิธีการทำลายข้อมูล
                  หรือลบทิ้งซึ่งข้อมูลภายในระยะเวลา 30 วัน
                  นับแต่วันสิ้นสุดระยะเวลาดังกล่าว&nbsp;</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong>6. สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong></span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.1 สิทธิในการเพิกถอนความยินยอม:
                  เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลได้ให้ไว้กับกลุ่มมิตรผลได้</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.2 สิทธิในการเข้าถึงข้อมูลส่วนบุคคล:
                  เจ้าของข้อมูลส่วนบุคคลมีสิทธิในการเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลที่เกี่ยวกับเจ้าของข้อมูลส่วนบุคคล
                  รวมถึงขอให้กลุ่มมิตรผลเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่เจ้าของข้อมูลส่วนบุคคลไม่ได้ให้ความยินยอมต่อกลุ่มมิตรผลได้</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.3 สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล&nbsp;</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.4 สิทธิในการลบข้อมูลส่วนบุคคล&nbsp;</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.5 สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล&nbsp;</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.6 สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง&nbsp;</span
                ></span
              >
            </p>

            <p style="margin-left: 40px">
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >6.7 สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล&nbsp;</span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >เจ้าของข้อมูลส่วนบุคคลสามารถติดต่อมายังกลุ่มมิตรผลเพื่อดำเนินการยื่นคำร้องขอดำเนินการตามสิทธิข้างต้น
                  ทั้งนี้เจ้าของข้อมูลส่วนบุคคลไม่จำเป็นต้องเสียค่าใช้จ่ายใดๆ
                  โดยกลุ่มมิตรผลจะพิจารณาและแจ้งผลการพิจารณาตามคำร้องของเจ้าของข้อมูลส่วนบุคคลภายใน
                  30 วันนับแต่วันที่ได้รับคำร้องขอดังกล่าว</span
                ></span
              >
            </p>

            <p>
              <br />
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong>7. ช่องทางการติดต่อ</strong></span
                ></span
              >
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >กรณีมีข้อสงสัยหรือต้องการสอบถามรายละเอียดเพิ่มเติมเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลหรือขอใช้สิทธิ
                  ได้ที่</span
                ></span
              ><br />
              <span
                style="font-family: Tahoma, Geneva, sans-serif; font-size: 14px"
                >สถานที่ติดต่อ : บริษัท น้ำตาลมิตรผล จำกัด<br />
                เลขที่ 2 อาคารเพลินจิตเซ็นเตอร์ ชั้น 3 ถนนสุขุมวิท&nbsp;<br />
                แขวงคลองเตย เขตคลองเตย กรุงเทพมหานคร<br />
                E-mail&nbsp; :
              </span>
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  ><strong
                    >8. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล</strong
                  ></span
                ></span
              ><br />
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >กลุ่มมิตรผลจะทำการพิจารณาทบทวนนโยบายความเป็นส่วนตัวเป็นประจำเพื่อให้สอดคล้องกับกฎหมาย
                  แนวปฏิบัติ และข้อบังคับที่เกี่ยวข้อง ทั้งนี้
                  หากมีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัว&nbsp;
                  กลุ่มมิตรผลจะแจ้งให้เจ้าของข้อมูลส่วนบุคคลทราบใน website
                  ของกลุ่มมิตรผลโดยเร็วที่สุด &nbsp;</span
                ></span
              ><br />
              &nbsp;
            </p>

            <p>
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >หากพบเห็นการกระทำใดที่ไม่เป็นไปตามนโยบายกลุ่มมิตรผลฉบับนี้
                  สามารถร้องเรียนการกระทำดังกล่าวแก่กลุ่มมิตรผลได้ที่ช่องทางการติดต่อข้างต้น</span
                ></span
              >
            </p>

            <p>
              <br />
              <span style="font-family: Tahoma, Geneva, sans-serif"
                ><span style="font-size: 14px"
                  >*กลุ่มมิตรผล หมายถึง บริษัท น้ำตาลมิตรผล จำกัด
                  และบริษัทในเครือ</span
                ></span
              >
            </p>

            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              style="width: 80px"
            >
              <tbody>
                <tr>
                  <td>
                    <img
                      alt=""
                      src="https://www.mitrphol.com/zadmin/ckimage/image/PDPA/PDPA_logo-001.png"
                      style="width: 100%"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p style="text-align: center">&nbsp;</p>
            <p></p>
          </div> -->
          <!-- <p>Lorem ipsum dolor sit amet consectetur. Vitae malesuada lorem mattis tortor nibh egestas. Quam tortor feugiat eleifend egestas tortor. Cursus cras pretium purus sagittis arcu. Facilisis est amet dignissim risus venenatis posuere. Amet bibendum risus scelerisque amet viverra. Fames non lorem eget amet non. Faucibus non leo tellus rutrum diam. Nibh vehicula pulvinar nibh adipiscing ut vel. Massa a enim elementum interdum. Vitae lectus lacus et orci imperdiet orci magna. Nunc sodales arcu ornare tincidunt cursus at. Est et donec enim cras sollicitudin in. Habitasse eget urna ac nisl euismod fusce. At enim id justo urna lectus.</p> -->
        </div>

        <div class="btn-accept">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="agreeTerms"
              (change)="onCheckboxChange($event)"
            />
            <label class="form-check-label" for="agreeTerms"
              >ข้าพเจ้ายอมรับในข้อกำหนดและเงื่อนไข</label
            >
          </div>
          <button
            class="btn"
            [disabled]="!isAgreeChecked"
            (click)="onAcceptClick()"
          >
            ยอมรับ
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
