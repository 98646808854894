import { Component } from '@angular/core';

@Component({
  selector: 'app-q-to-weight-in-cane',
  standalone: true,
  imports: [],
  templateUrl: './q-to-weight-in-cane.component.html',
  styleUrl: './q-to-weight-in-cane.component.css'
})
export class QToWeightInCaneComponent {

}
