import { Component, OnInit, Inject, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-fullcalendar-cane',
  standalone: true,
  imports: [FullCalendarModule],
  templateUrl: './fullcalendar-cane.component.html',
  styleUrls: ['./fullcalendar-cane.component.css'],
  encapsulation: ViewEncapsulation.None  // ปิด encapsulation เพื่อให้ CSS ใช้ได้กับ FullCalendar
})
export class FullcalendarCaneComponent implements OnInit {
  id: string | null = null;
  calendarOptions: any;
  bp_role_data: any[] = [];

  constructor(
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    // Only initialize the calendar options if running in the browser
    if (isPlatformBrowser(this.platformId)) {
      this.initializeCalendar();
      const storedbp_role_data = localStorage.getItem('bp_role_data');
      this.bp_role_data = storedbp_role_data ? JSON.parse(storedbp_role_data) : [];
      console.log(this.bp_role_data);
    }
  }

  initializeCalendar() {
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: ''
      },
      events: this.loadEvents(),
      eventColor: '#378006',
      locale: 'th',
      showNonCurrentDates: false,
      fixedWeekCount: false,
      height: 'auto',
      dateClick: this.handleDateClick.bind(this), // เพิ่ม event handler
      displayEventTime: false // ปิดการแสดงเวลาในอีเวนต์ทั้งหมด
    };
}


  handleDateClick(arg: any) {
    // ลบคลาส 'active' ออกจากวันที่ก่อนหน้า
    const prevActiveDate = document.querySelector('.fc-daygrid-day.active');
    if (prevActiveDate) {
      prevActiveDate.classList.remove('active');
    }

    // เพิ่มคลาส 'active' ให้กับวันที่ที่ถูกเลือก
    arg.dayEl.classList.add('active');
  }

  loadEvents() {
    return [
      {
        title: 'รอขนส่ง',
        start: '2024-10-21T08:00:00',
        end: '2024-10-21T10:00:00',
        color: '#4C6EF5'
      },
      {
        title: 'เสร็จสิ้น',
        start: '2024-10-21T14:00:00',
        end: '2024-10-21T15:00:00',
        color: '#4CAF50'
      },

    ];
  }
}
